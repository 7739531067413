import React, { useState, useEffect } from "react";
import {
  Form,
  Button,
  Container,
  Row,
  Col,
  Card,
  Alert,
  Modal,
} from "react-bootstrap";
import axios from "axios";
import { Link } from "react-router-dom";
import { useParams } from "react-router-dom";

const AddPayoutsID = () => {
  const { id } = useParams();
  const [formData, setFormData] = useState({
    // payoutId: "",
    payoutDate: "",
    amount: "",
    payoutStartDate: "",
    payoutEndDate: "",
    remarks: "",
    artistId: id,
    paymentOption: "1",
    receipt: null,
    bank_transaction_id: "",
    pay_type: "",
    isreceipt: false,
    referenceId: "",
  });

  const [artists, setArtists] = useState([]); // To store the list of artists
  const [error, setError] = useState("");
  const [success, setSuccess] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);

  // Fetch artists list when component loads
  //   useEffect(() => {
  //     const fetchArtists = async () => {
  //       try {
  //         const response = await axios.get("http://localhost:5000/api/artists");
  //         console.log("responseee", response.data);
  //         setArtists(response.data);
  //       } catch (error) {
  //         console.error("Error fetching artists:", error);
  //         setError("Failed to load artists.");
  //       }
  //     };

  //     fetchArtists();
  //   }, []);

  const [showModal, setShowModal] = useState(false);
  const handleModalClose = () => {
    setShowModal(false);
  };

  // Handle form change
  const handleChange = (e) => {
    const { name, value, type, checked, files } = e.target;
    // if (name === "paymentOption" && (value === "2" || value === "3"))
    //   setShowModal(true);
    // else {
    // setFormData({
    //   ...formData,
    //   [name]: type === "checkbox" ? checked : value, // Toggle based on checkbox
    // });
    if (type === "file") {
      // Handle file input change
      setFormData({
        ...formData,
        receipt: files[0], // Store the selected file
      });
    } else {
      setFormData({
        ...formData,
        [name]: type === "checkbox" ? checked : value, // Toggle based on checkbox
      });
    }
    // }
  };

  // Handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    setError("");
    setSuccess("");
    setIsSubmitting(true);

    // Basic validation
    if (!formData.payoutDate || !formData.amount || !formData.artistId) {
      setError("Please fill in all required fields.");
      setIsSubmitting(false);
      return;
    }

    try {
      console.log("formdata submitted", formData);
      const response = await axios.post(
        "https://fttunes.com/api/payouts",
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data", // Required for file uploads
          },
        }
      );
      if (response.status === 201) {
        setSuccess("Payout created successfully!");
        setFormData({
          //   payoutId: "",
          payoutDate: "",
          amount: "",
          payoutStartDate: "",
          payoutEndDate: "",
          remarks: "",
          artistId: id,
          paymentOption: "1",
          receipt: null,
          bank_transaction_id: "",
          pay_type: "",
          isreceipt: false,
          referenceId: "",
        });
      } else {
        setError("Failed to create payout.");
      }
    } catch (error) {
      console.error("Error creating payout:", error);
      setError("An error occurred while creating the payout.");
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <Container className="mt-4">
      <Row className="justify-content-center">
        <Col md={8}>
          <Card bg="dark" text="white">
            <Card.Body>
              <Card.Title className="text-center">Create New Payout</Card.Title>
              {error && <Alert variant="danger">{error}</Alert>}
              {success && <Alert variant="success">{success}</Alert>}
              <Form onSubmit={handleSubmit}>
                {/* <Form.Group controlId="formPayoutId">
                  <Form.Label>Payout ID</Form.Label>
                  <Form.Control
                    type="text"
                    name="payoutId"
                    value={formData.payoutId}
                    onChange={handleChange}
                    required
                  />
                </Form.Group> */}
                {/* <Col> */}
                <Form.Group controlId="formArtistId">
                  <Form.Label>Payment Option</Form.Label>
                  <Form.Control
                    as="select"
                    name="paymentOption"
                    value={formData.paymentOption}
                    onChange={handleChange}
                    required
                  >
                    {/* <option value="">Select Option</option> */}
                    <option value="1">Cash</option>
                    <option value="2">UPI</option>
                    <option value="3">Credit / Debit Card</option>
                    <option value="4">Bank Transfer</option>
                  </Form.Control>
                </Form.Group>
                {/* </Col> */}

                {formData.paymentOption === "4" && (
                  <Row>
                    <Col>
                      <Form.Group
                        // as={Row}
                        controlId="bank_transaction_id"
                        className="form-group"
                      >
                        <Form.Label>UTR No.</Form.Label>
                        <Form.Control
                          type="text"
                          name="bank_transaction_id"
                          autoComplete="off"
                          data-valid="required"
                          className="form-control"
                          onChange={handleChange}
                          // onKeyUp={handleAmountKeyUp}
                        />
                      </Form.Group>
                    </Col>
                    <Col>
                      <Form.Group
                        // as={Row}
                        controlId="pay_type"
                        className="form-group"
                      >
                        <Form.Label>Select payment mode</Form.Label>
                        <Form.Control
                          as="select"
                          data-valid="required"
                          className="form-control"
                          name="pay_type"
                          onChange={handleChange}
                        >
                          <option value="NEFT">NEFT</option>
                          <option value="RTGS">RTGS</option>
                          <option value="IMPS">IMPS</option>
                        </Form.Control>
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                {(formData.paymentOption === "2" ||
                  formData.paymentOption === "3") && (
                  <Row>
                    <Col>
                      <Form.Group
                        // as={Row}
                        controlId="referenceId"
                        className="form-group"
                      >
                        <Form.Label>Refernce Id</Form.Label>
                        <Form.Control
                          type="text"
                          name="referenceId"
                          autoComplete="off"
                          data-valid="required"
                          className="form-control"
                          onChange={handleChange}
                          // onKeyUp={handleAmountKeyUp}
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col>
                    <Form.Group controlId="formPayoutDate">
                      <Form.Label>Payout Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="payoutDate"
                        value={formData.payoutDate}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="formAmount">
                      <Form.Label>Amount</Form.Label>
                      <Form.Control
                        type="number"
                        name="amount"
                        value={formData.amount}
                        onChange={handleChange}
                        required
                      />
                    </Form.Group>
                  </Col>
                  {/* <Col>
                    <Form.Group controlId="formArtistId">
                      <Form.Label>Artist</Form.Label>
                      <Form.Control
                        as="select"
                        name="artistId"
                        value={formData.artistId}
                        onChange={handleChange}
                        required
                      >
                        <option value="">Select Artist</option>
                        {artists.map((artist) => (
                          <option key={artist.id} value={artist.artistId}>
                            {artist.artistId} ({artist.firstName}{" "}
                            {artist.lastName})
                          </option>
                        ))}
                      </Form.Control>
                    </Form.Group>
                  </Col> */}
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="formPayoutStartDate">
                      <Form.Label>Payout Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="payoutStartDate"
                        value={formData.payoutStartDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="formPayoutEndDate">
                      <Form.Label>Payout End Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="payoutEndDate"
                        value={formData.payoutEndDate}
                        onChange={handleChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="formRemarks">
                  <Form.Label>Remarks (Optional)</Form.Label>
                  <Form.Control
                    as="textarea"
                    name="remarks"
                    value={formData.remarks}
                    onChange={handleChange}
                    rows={3}
                  />
                </Form.Group>

                <Form.Group controlId="formTNC">
                  <Form.Check
                    type="checkbox"
                    name="isreceipt"
                    label="Attach Receipt"
                    checked={formData.isreceipt}
                    onChange={handleChange}
                    // required
                  />
                </Form.Group>

                {formData.isreceipt && (
                  <Form.Group controlId="formReceiptFile">
                    <Form.Label>Upload Receipt</Form.Label>
                    <Form.Control
                      type="file"
                      name="receipt"
                      onChange={handleChange}
                      accept=".pdf,.jpg,.jpeg,.png"
                    />
                  </Form.Group>
                )}

                <Button
                  variant="primary"
                  type="submit"
                  disabled={isSubmitting}
                  className="mt-3 theme-btn"
                  block
                >
                  {isSubmitting ? "Submitting..." : "Submit Payout"}
                </Button>
              </Form>
            </Card.Body>
          </Card>
        </Col>
      </Row>

      <Modal
        show={showModal}
        onHide={handleModalClose}
        backdrop="static"
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>Online TopUp</Modal.Title>
        </Modal.Header>
        <form
          name="frmTransaction"
          method="POST"
          action="https://tripoholidays.com/agent/sabpaisa"
          id="frmTransaction"
          className="has-validation-callback payment_gateway_form"
        >
          <Modal.Body>
            <div className="pay_gate_note">
              <p>
                <b>Note:</b> Recommended Payment Gateway Sabpaisa.
              </p>
            </div>
            <div className="row payment_gateways_modal">
              <div className="col-md-3" style={{ display: "none" }}>
                <div className="form-group">
                  <h5>Payment Gateways</h5>
                  <ul style={{ listStyle: "none", paddingLeft: "5px" }}>
                    <li>
                      <label htmlFor="">
                        <input
                          data-id="0"
                          name="payment_method"
                          className="selectpaymentgateway"
                          type="radio"
                          value="Sabpaisa"
                        />{" "}
                        sabpaisa
                      </label>
                    </li>
                  </ul>
                </div>
              </div>
              <div className="col-md-5">
                <div className="form-group">
                  <table className="table table-bordered table-hover text-nowrap payment_method">
                    <thead>
                      <tr>
                        <th>Payment Method</th>
                        <th style={{ width: "80px" }}>Surcharge</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <td>
                          <label htmlFor="creditcard">
                            <input
                              data-atype=""
                              data-charges="0"
                              id="creditcard"
                              name="payment_type"
                              type="radio"
                              className="payment_type"
                              value="Credit Card"
                            />{" "}
                            <i className="fa-solid fa-credit-card"></i> Sabpaisa
                          </label>
                        </td>
                        <td
                          style={{ textAlign: "center" }}
                          className="creditcardamount"
                        >
                          0
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>
              <div className="col-md-4">
                <div className="form-group">
                  <label>Deposit Amount</label>
                  <input
                    id="price"
                    type="text"
                    className="form-control onlynumber"
                    placeholder=""
                    name="totalAmount"
                    // onKeyPress={(event) => handleAmountKeyUp(event)}
                    // onKeyUp={(event) => validate_amount(event.target.value)}
                    // onChange={(event) => validate_amount(event.target.value)}
                  />
                </div>
                <div className="form-group" style={{ marginBottom: "29px" }}>
                  <p>
                    <b>Total:</b>{" "}
                    <span
                      className="totl_amount"
                      style={{
                        fontSize: "18px",
                        lineHeight: "21px",
                        fontWeight: "bold",
                      }}
                    ></span>
                  </p>
                </div>
                <input type="hidden" name="amount" id="net_pay" />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              id="payButton"
              name="submitted"
              className="btn btn-success continue"
            >
              <span
                className="fa fa-hand-point-right"
                aria-hidden="true"
              ></span>{" "}
              Continue
            </button>{" "}
            &nbsp;
            <Link
              // to="#"
              className="btn btn-danger"
              onClick={handleModalClose}
            >
              <span className="fa fa-times-circle" aria-hidden="true"></span>{" "}
              Cancel
            </Link>
          </Modal.Footer>
        </form>
      </Modal>
    </Container>
  );
};

export default AddPayoutsID;
